export const environment = {
  production: false,

  //paymentMode: 'TEST',
  //currency: 'INR',
  //pgName: 'Razor_Pay',

  //razorpayApikey: 'rzp_test_3hrDFKmoVBOiZp',
  //razorpaySecretkey: 'jiEPbumcSfJicdNIm8DuaTah',

  // tabbyPromoPublicKey: 'pk_test_01900b95-bc85-4878-18a9-d89db1891bb0',
  // merchantCode: 'Vedatmaare',

  recaptchaSiteKey: '6LfeWVYqAAAAAI7EeuOweDrhO4HSUx-bU2oabo_k', //with Chinnu s' support, taken new site key,since there was issue after migrating to new mockup server


  //Devlopment
  //=====================================================
  //apiUrl: 'http://vedatmadev.aabasoft.info/vedatmaapi',
  //applicationlink: 'http://vedatmadev.aabasoft.info/',
  //applicationSubFolder: 'vedatmawebsite',
  //uploadUrl: 'http://vedatmadev.aabasoft.info/vedatmaapi/Media/Reports/',
  //paymentRedirectUrl: 'http://vedatmadev.aabasoft.info/vedatmaapi/payment-response-razorpay',
  //stripe_paymentapi: 'http://vedatmadev.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //firebaseConfig: {
  // apiKey: "AIzaSyB-qVD6HSgUdboqKJvM-J-EwCxITUlJ1y8",
  // authDomain: "vedatmadev.firebaseapp.com",
  // databaseURL: "https://vedatmadev-default-rtdb.firebaseio.com",
  // projectId: "vedatmadev",
  // storageBucket: "vedatmadev.appspot.com",
  // messagingSenderId: "690546694217",
  // appId: "1:690546694217:web:0aacab323694c8cc4e4798",
  // measurementId: "G-6KDKXXGP84"
  //},

  // Demo
  // ========================================================

  // apiUrl: 'http://vedatma.aabasoft.info/studentConnect/',
  // applicationlink: 'http://vedatma.aabasoft.info/',
  // applicationSubFolder: 'student',
  // uploadUrl: 'http://vedatma.aabasoft.info/studentConnect/Media/Reports/',
  // stripe_paymentapi: 'http://vedatma.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  // paymentRedirectUrl: 'http://vedatma.aabasoft.info/studentConnect/payment-response-razorpay',
  // firebaseConfig: {
  //   apiKey: "AIzaSyCQp6t105Gl-pxDZLuYM_YatdWUiFUaZ4I",
  //   authDomain: "vedatmademo.firebaseapp.com",
  //   databaseURL: "https://vedatmademo-default-rtdb.firebaseio.com",
  //   projectId: "vedatmademo",
  //   storageBucket: "vedatmademo.appspot.com",
  //   messagingSenderId: "389225572613",
  //   appId: "1:389225572613:web:49ac1cda320e8bf48c2f6c"
  // },



  // Preprod
  // ========================================================

  //apiUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/',
  //applicationlink: 'http://vedatmapreprod.aabasoft.info/',
  //applicationSubFolder: 'student',
  //uploadUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/Media/Reports/',
  //stripe_paymentapi: 'http://vedatmapreprod.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  //paymentRedirectUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/payment-response-razorpay',
  //firebaseConfig: {
  //  apiKey: "AIzaSyA3qdDCSQB5q46jLGgnreWxO7ApCc-Ywsk",
  //  authDomain: "vedatmapreprod.firebaseapp.com",
  //  projectId: "vedatmapreprod",
  //  storageBucket: "vedatmapreprod.firebasestorage.app",
  //  messagingSenderId: "598682543658",
  //  appId: "1:598682543658:web:22f6f06f6c78cd4d284553",
  //  measurementId: "G-1FPBFN7NPK"
  //},


  //preprod with demo firebase

  // apiUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/',
  // applicationlink: 'http://vedatmapreprod.aabasoft.info/',
  // applicationSubFolder: 'student',
  // uploadUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/Media/Reports/',
  // stripe_paymentapi: 'http://vedatmapreprod.aabasoft.info/VedatmaStripeAPI/create-checkout-session',
  // paymentRedirectUrl: 'http://vedatmapreprod.aabasoft.info/studentConnect/payment-response-razorpay',
  // firebaseConfig: {
  //   apiKey: "AIzaSyCQp6t105Gl-pxDZLuYM_YatdWUiFUaZ4I",
  //   authDomain: "vedatmademo.firebaseapp.com",
  //   databaseURL: "https://vedatmademo-default-rtdb.firebaseio.com",
  //   projectId: "vedatmademo",
  //   storageBucket: "vedatmademo.appspot.com",
  //   messagingSenderId: "389225572613",
  //   appId: "1:389225572613:web:49ac1cda320e8bf48c2f6c"
  // },

  // Live
  // =======================================================

  apiUrl: 'https://vedatmacounselling.com/counsellingconnect',
  applicationlink: 'https://vedatmacounselling.com/',
  applicationSubFolder: '',
  uploadUrl: 'https://vedatmacounselling.com/practitionerconnect/Media/Reports/',
  stripe_paymentapi: 'https://stripeapi.vedatmacounselling.com/create-checkout-session',
  paymentRedirectUrl: 'https://vedatmacounselling.com/counsellingconnect/payment-response',
  firebaseConfig: {
    apiKey: "AIzaSyCfNItDKTf1g7mscz3Wu1YXANLbeiJWnPM",
    authDomain: "vedatma-production.firebaseapp.com",
    databaseURL: "https://vedatma-production-default-rtdb.firebaseio.com",
    projectId: "vedatma-production",
    storageBucket: "vedatma-production.appspot.com",
    messagingSenderId: "236929135030",
    appId: "1:236929135030:web:f1f7c2e483e0288b0cd117"
  },

  paymentMode: 'LIVE',
  currency: 'INR',
  pgName: 'Razor_Pay',

  razorpayApikey: 'rzp_live_SDGD11xwlkfyeI',
  razorpaySecretkey: 'UVh4mudA2734TRwGLRuArGBN',

};
